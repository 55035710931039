.GlyphLock {
  display: flex;
}

.GlyphLock-dial {
  flex: 1 1;
}

.GlyphLock-dial + .GlyphLock-dial {
  margin-left: 2%;
}
