.GlyphLockDial {
}

.GlyphLockDial-inner {
  background: white;
  position: relative;
  height: 0;
  /* NOTE: padding-top is set programmatically */
  overflow: hidden;
  user-select: none;
}

.GlyphLockDial-positioner {
  position: absolute;
  /* NOTE: top is set programmatically */
  left: 0;
  right: 0;
  bottom: 0;
}

.GlyphLockDial img {
  display: block; /* avoids extra margin */
}

.GlyphLockDial-frame {
  position: absolute;
  border: 4px solid;
  /* NOTE: top and bottom are set programmatically */
  right: 0;
  left: 0;
}

.GlyphLockDial-frame-unlocked {
  border-color: green;
}

.GlyphLockDial-above-frame {
  position: absolute;
  /* NOTE: bottom is set programmatically */
  top: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.15);
}

.GlyphLockDial-below-frame {
  position: absolute;
  /* NOTE: top is set programmatically */
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.15);
}
