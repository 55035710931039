.App {
}

.App-glyph-lock-wrap {
  border: 1px solid #888;
}

.App-center {
  font-family: Arial, Helvetica, serif;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
}

.App-inner {
  max-width: 500px;
  padding: 10px;
  margin: 0 auto;
}

.App-forgot {
  margin-top: 10px;
  text-align: center;
}

.App a {
  color: #666;
}

.App a:hover {
  color: #222;
}
